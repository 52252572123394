<template>
  <div class="custom-toggle" @click="toggle">
    <input type="checkbox" v-model="value" class="hidden" />
    <div class="toggle-track"></div>
    <div class="toggle-thumb"></div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  methods: {
    toggle () {
      this.value = !this.value
    },
  },
}
</script>

<style scoped>
.custom-toggle {
  position: relative;
  width: 48px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}

.toggle-track {
  width: 48px;
  height: 100%;
  background-color: var(--color-tan-400);
  border-radius: 30px;
  transition: background-color 0.4s;
  box-shadow: 2px 2px 0 var(--color-tan-300); /* Hard shadow */
}

.toggle-thumb {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: var(--color-white);
  border-radius: 50%;
  transition: transform 0.4s;
}

.custom-toggle input:checked + .toggle-track {
  background-color: var(--color-primary-600); /* Purple color when toggled on */
}

.custom-toggle input:checked + .toggle-track + .toggle-thumb {
  transform: translateX(24px); /* Move the knob to the right when toggled on */
}
</style>