<template>
  <TextInputElement
    :label="label"
    placeholder="YYYY-MM-DD"
    errorRegex="\d{4}-\d{2}-\d{2}"
    :model-value="value"
    @update:model-value="$emit('input', $event)"
  >
    <template #append>
      <q-avatar
        size="16px"
        font-size="16px"
        class="cal-icon"
      >
        <img :src="calendarIcon" alt="calendar icon" />
      </q-avatar>
      <q-popup-proxy
        ref="dateMenu"
        transition-show="scale"
        transition-hide="scale"
        anchor="center right"
        self="center left"
      >
        <q-date
          today-btn
          no-unset
          color="our-purple"
          mask="YYYY-MM-DD"
          :model-value="value"
          @update:model-value="updateValue"
          :options="dateOptions"
        >
          <span v-if="PUBLIC_APP" class="free-text">{{ $t("setting.freeDates") }}</span>
        </q-date>
      </q-popup-proxy>
    </template>
  </TextInputElement>
  <BaseTooltip v-if="tooLateError" always-show is-error>
    {{ $t("setting.freeDates") }}
  </BaseTooltip>
</template>

<script>
import TextInputElement from './TextInputElement.vue'
import BaseTooltip from '@/components/BaseTooltip'
import { dateIsOldEnough } from '@/helpers/validate'
import calendarIcon from '@/assets/calendar.svg'

export default {
  components: {
    TextInputElement,
    BaseTooltip,
  },
  props: {
    label: String,
    placeholder: String,
    value: String
  },
  computed: {
    tooLateError () {
      return !this.dateOptions(this.value)
    },
  },
  methods: {
    updateValue (newValue) {
      this.$emit('input', newValue)
      this.$refs.dateMenu.hide()
    },
    dateOptions (date) {
      return dateIsOldEnough(date)
    },
  },
  data () {
    return {
      calendarIcon
    }
  },
  emits: ['input']
}
</script>

<style lang="scss">
.free-text {
  font-family: var(--primary-font);
}
</style>

<style scoped lang="scss">
.cal-icon {
  cursor: pointer;
}
</style>