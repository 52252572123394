<template>
  <button @click="$emit('click')" v-ripple :style="{ padding }" class="icon-button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  emits: ['click'],
  props: {
    padding: {
      type: String,
      default: '11px'
    }
  }
}
</script>

<style scoped lang="scss">
.icon-button {
  position: relative;
  background: var(--color-white);
  cursor: pointer;
  border: 1px solid var(--color-tan-950);
  box-shadow: 4px 4px 0 var(--color-tan-300);
  line-height: 0;
}
</style>
