<template>
  <div>
    <form @submit.prevent="clickSearch('links')">
      <InputDates />

      <InputWebsites />

      <InputUrlHost />

      <InputNumberOf :label="$t('setting.numberUrls')" />

      <FatButton width='100%'>
        {{ $t("setting.button.urls") }}
      </FatButton>
    </form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import InputDates from '@/components/input/InputDates'
import InputWebsites from '@/components/input/InputWebsites'
import InputNumberOf from '@/components/input/InputNumberOf'
import InputUrlHost from '@/components/input/InputUrlHost'
import FatButton from '@/components/input/FatButton'
const { mapActions } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    InputDates,
    InputWebsites,
    InputNumberOf,
    InputUrlHost,
    FatButton
  },
  methods: {
    ...mapActions(['clickSearch'])
  }
}
</script>

<style scoped lang="scss">
form > * {
  margin-top: var(--spacing-5);
}
</style>
