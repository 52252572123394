<template>
  <div>
    <form @submit.prevent="clickSearch('activity')">
      <InputDates />
      <InputWebsites />
      <FatButton width='100%'>
        {{ $t("setting.button.activity") }}
      </FatButton>
    </form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import InputDates from '@/components/input/InputDates'
import InputWebsites from '@/components/input/InputWebsites'
import FatButton from '@/components/input/FatButton'
const { mapActions } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    InputDates,
    InputWebsites,
    FatButton
  },
  methods: {
    ...mapActions(['clickSearch'])
  }
}
</script>

<style scoped lang="scss">
form > * {
  margin-top: var(--spacing-5);
  font-family: var(--primary-font);
}
</style>
