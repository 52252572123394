<template>
  <div class="search-control">
    <div class="left-part">
      <!--TODO: this toggle can have a shared base component with the one in TypeTabs.vue-->
      <q-btn-toggle
        :model-value="displayType"
        @update:model-value="$emit('update:displayType', $event)"
        :options="[
          {
            value: 'table',
            label: 'Table',
          },
          {
            value: 'cards',
            label: 'Cards',
          },
          {
            value: 'media',
            label: 'Media',
          }
        ]"
      >
      </q-btn-toggle>
      {{ numResults }} results
    </div>
    <div class="right-part">
      <LabelToggle
        :model-value="displayMedia"
        @update:model-value="$emit('update:displayMedia', $event)"
        label="Show Media"
      />
      <LabelToggle
        :model-value="blurThumbnails"
        @update:model-value="$emit('update:blurThumbnails', $event)"
        label="Blur thumbnails"
      />
      <!--TODO: there's another dropdown like this in LanguagePickerDropdown.vue-->
      <!--<q-select
        label="Sort by: Date"
        :model-value="sortBy"
        @update:model-value="$emit('update:sortBy', $event)"
        :options="['date', 'potatoes', 'apples']"
      />-->
      <ExportSelect @select="handleExportSelect" />
      <OutlinedIconButton @click="$emit('share')">
        <img :src="shareIcon" alt="share icon" style="height: 16px; width: 16px" />
      </OutlinedIconButton>
      <Button @click="$emit('summary')">
        See summary
      </Button>
    </div>
  </div>
</template>

<script>
import LabelToggle from '../input/LabelToggle.vue'
import ExportSelect from '../input/ExportSelect.vue'
import OutlinedIconButton from '../input/OutlinedIconButton.vue'
import Button from '../input/Button.vue'

import shareIcon from '../../assets/share.svg'

export default {
  name: 'SearchControl',
  data () {
    return {
      shareIcon
    }
  },
  components: {
    LabelToggle,
    ExportSelect,
    OutlinedIconButton,
    Button,
  },
  props: {
    displayType: String,
    numResults: Number,
    displayMedia: Boolean,
    blurThumbnails: Boolean,
    sortBy: String,
  },
  methods: {
    handleExportSelect (option) {
      this.$emit('doExport', option)
    },
  }
}
</script>

<style scoped lang="scss">
.search-control {
  display: flex;
  gap: var(--spacing-1);
}

.left-part {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxl);
}

.right-part {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: var(--spacing-xxl);
}
</style>