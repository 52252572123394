<template>
  <div>
    <form @submit.prevent="clickSearch('search')">
      <SearchInput :placeholder="'Search'" v-model="search" :shadowColor="'var(--color-tan-300)'" />
      <InputSearchTerm />

      <InputDates />

      <InputWebsites />

      <FatButton width='100%'>
        {{ $t("setting.button.search") }}
      </FatButton>
    </form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import SearchInput from '@/components/input/SearchInput.vue'
import InputSearchTerm from '@/components/input/InputSearchTerm'
import InputDates from '@/components/input/InputDates'
import InputWebsites from '../input/InputWebsites.vue'
import FatButton from '@/components/input/FatButton'
const { mapActions } = createNamespacedHelpers('searchSettings')

export default {
  data () {
    return {
      search: '',
    }
  },
  components: {
    SearchInput,
    InputSearchTerm,
    InputDates,
    InputWebsites,
    FatButton,
  },
  methods: {
    ...mapActions(['clickSearch']),
  },
}
</script>

<style scoped lang="scss">
form > * {
  margin-top: var(--spacing-5);
}
</style>
