<template>
  <q-td :props="cell" :auto-width="cell.col.autoWidth">
    <TextCell v-if="cell.col.name === 'text'" :cell="cell"/>
    <template v-else-if="cell.value">
      {{ cell.value }}
    </template>
  </q-td>
</template>

<script>
import TextCell from './TextCell.vue'

export default {
  components: {
    TextCell,
  },
  props: {
    cell: Object,
  },
}
</script>