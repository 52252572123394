<template>
  <DropdownWrapper
    :isOpen="isOpen"
    :label="'Export'"
    :width="'7.857rem'"
    @toggle="toggleDropdown"
  >
    <div :class="['dropdown-list', `dropdown-list-${mode}-mode`]">
      <div
        v-for="(option, index) in options"
        :key="index"
        :class="['dropdown-item', `dropdown-item-${mode}-mode`]"
        @click="selectOption(option.value)"
      >
        {{ option.label }}
      </div>
  </div>
  </DropdownWrapper>
</template>

<script>
import DropdownWrapper from './DropdownWrapper.vue'

export default {
  name: 'SingleSelect',
  props: {
    mode: {
      type: String,
      default: 'light'
    }
  },
  data () {
    return {
      isOpen: false,
      options: [
        { label: 'CSV', value: 'csv' },
        { label: 'JSON', value: 'json' }
      ],
    }
  },
  components: {
    DropdownWrapper
  },
  methods: {
    toggleDropdown () {
      this.isOpen = !this.isOpen
    },
    selectOption (option) {
      this.$emit('select', option)
      this.isOpen = false
    },
  },

}
</script>

<style scoped>
.dropdown-list {
  position: absolute;
  width: 100%;
  border: var(--border-width) solid;
  margin-top: 4px;
  z-index: 1;
}

.dropdown-list-light-mode {
  border-color: var(--color-tan-950);
  background-color: var(--color-white);
  box-shadow: var(--element-box-shadow) var(--color-tan-300);
}

.dropdown-list-dark-mode {
  border-color: var(--color-tan-100);
  background-color: var(--color-primary-950);
  box-shadow: var(--element-box-shadow) var(--color-primary-800);
}

.dropdown-item {
  padding: var(--element-padding-sm);
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item-light-mode {
  color: var(--color-tan-600);
}

.dropdown-item-dark-mode {
  color: var(--color-tan-400);
}

.dropdown-item:hover {
  color: var(--color-primary-950);
  background-color: var(--color-primary-100);
}
</style>