import moment from 'moment'
import { isEmpty, cloneDeep } from 'lodash'
import router from '@/router'
import auth0 from '@/plugins/auth0'
import { validInput } from '@/helpers/validate'
import { queries as buildQueries, updateUrl, isTool } from '@/helpers/searchQuery'
const { FOURCHAN, EIGHTKUN, PARLER, GAB, WIN, POAL, TELEGRAM, GETTR, BITCHUTE_VIDEO, BITCHUTE_COMMENT, MEWE, WIMKIN, RUMBLE_COMMENT, RUMBLE_VIDEO, MINDS, LBRY_VIDEO, LBRY_COMMENT, VK, TRUTH_SOCIAL, TIKTOK_VIDEO, TIKTOK_COMMENT, RUTUBE_COMMENT, RUTUBE_VIDEO, OK, BLUESKY, FEDIVERSE } = require('@/constants/sites')
const { TIMELINE, SEARCH, LINKS, ACTIVITY } = require('@/constants/tools')

export default {
  namespaced: true,
  state: {
    settings: {
      searchTerm: 'qanon',
      startDate: moment().subtract(0.5, 'year').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      websites: [
        {
          name: EIGHTKUN,
          label: '8kun',
          active: false
        },
        {
          name: FOURCHAN,
          label: '4chan',
          active: false
        },
        {
          name: BLUESKY,
          label: 'Bluesky',
          active: false
        },
        {
          name: BITCHUTE_VIDEO,
          label: 'Bitchute Video',
          active: false
        },
        {
          name: BITCHUTE_COMMENT,
          label: 'Bitchute Comment',
          active: false
        },
        {
          name: FEDIVERSE,
          label: 'Fediverse',
          active: false
        },
        {
          name: GAB,
          label: 'Gab',
          active: true
        },
        {
          name: GETTR,
          label: 'Gettr',
          active: false
        },
        {
          name: LBRY_COMMENT,
          label: 'LBRY Comment',
          active: false
        },
        {
          name: LBRY_VIDEO,
          label: 'LBRY Video',
          active: false
        },
        {
          name: MEWE,
          label: 'MeWe',
          active: false
        },
        {
          name: MINDS,
          label: 'Minds',
          active: false
        },
        {
          name: OK,
          label: 'OK',
          active: false
        },
        {
          name: PARLER,
          label: 'Parler',
          active: false
        },
        {
          name: POAL,
          label: 'Poal',
          active: false
        },
        {
          name: RUMBLE_VIDEO,
          label: 'Rumble Video',
          active: false
        },
        {
          name: RUMBLE_COMMENT,
          label: 'Rumble Comment',
          active: false
        },
        {
          name: RUTUBE_VIDEO,
          label: 'RUTUBE Video',
          active: false
        },
        {
          name: RUTUBE_COMMENT,
          label: 'RUTUBE Comment',
          active: false
        },
        {
          name: TELEGRAM,
          label: 'Telegram',
          active: false
        },
        {
          name: TIKTOK_VIDEO,
          label: 'TikTok Video',
          active: false
        },
        {
          name: TIKTOK_COMMENT,
          label: 'TikTok Comment',
          active: false
        },
        {
          name: TRUTH_SOCIAL,
          label: 'Truth Social',
          active: false
        },
        {
          name: VK,
          label: 'VK',
          active: false
        },
        {
          name: WIMKIN,
          label: 'WiMKiN',
          active: false
        },
        {
          name: WIN,
          label: 'Win Communities',
          active: false
        }
      ],
      numberOf: 10,
      interval: 'day',
      changepoint: false,
      esquery: 'content',
      hostRegex: true,
    },
    toolResults: {
      [TIMELINE]: [],
      [SEARCH]: [],
      [LINKS]: [],
      [ACTIVITY]: [],
    },
    error: null,
    lastSearchSettings: {},
  },
  getters: {
    page () {
      return router.currentRoute.value.name
    },
    results (state, getters) {
      return state.toolResults[getters.page]
    },
    resultsAvailable (state, getters) {
      return getters.results.length > 0
    },
    loading (state, getters) {
      return !getters.resultsAvailable && !state.error
    },
    searchTerm (state) {
      return state.settings.searchTerm
    },
    startDate (state) {
      return state.settings.startDate
    },
    endDate (state) {
      return state.settings.endDate
    },
    websites (state) {
      return state.settings.websites
    },
    numberOf (state) {
      return state.settings.numberOf
    },
    interval (state) {
      return state.settings.interval
    },
    changepoint (state) {
      return state.settings.changepoint
    },
    esquery (state) {
      return state.settings.esquery
    },
    hostRegex (state) {
      return state.settings.hostRegex
    },
  },
  mutations: {
    setSearchTerm (state, val) {
      state.settings.searchTerm = val
    },
    setStartDate (state, val) {
      state.settings.startDate = val
    },
    setEndDate (state, val) {
      state.settings.endDate = val
    },
    setWebsiteActive (state, { i, val }) {
      state.settings.websites[i].active = val
    },
    resetWebsitesActiveState (state) {
      state.settings.websites.forEach(website => { website.active = false })
    },
    setNumberOf (state, val) {
      state.settings.numberOf = Number(val)
    },
    setInterval (state, val) {
      state.settings.interval = val
    },
    setChangepoint (state, val) {
      state.settings.changepoint = val
    },
    setEsQuery (state, val) {
      state.settings.esquery = val
    },
    setHostRegex (state, val) {
      state.settings.hostRegex = val
    },
    setAllSettings (state, val) {
      state.settings = val
    },
    setResults (state, { tool, results }) {
      state.toolResults[tool] = results
    },
    resetResults (state) {
      for (const tool in state.toolResults) {
        state.toolResults[tool] = []
      }
    },
    setError (state, val) {
      state.error = val
    },
    setLastSearchSettings (state, val) {
      state.lastSearchSettings = val
    },
  },
  actions: {
    loadSettings ({ commit }, val) {
      // WARNING this is dangerous!! since it basically takes input from the user/someone who linked the user the page
      // require guarentees for other getters/ mutations that state.settings is a particular shape
      commit('setAllSettings', val)
      // some components require the variables to be numbers so we put it
      // through the mutations which do that for us
      commit('setNumberOf', val.numberOf)
      // it's a string in the url but we want a boolean
      commit('setChangepoint', val.changepoint === 'true')
      commit('setHostRegex', val.hostRegex === 'true')
    },
    clearSearchWebsites ({ getters, commit }) {
      // the search tool only allows searching 1 website at a time so in case multiple are selected we need to reduce that
      if (getters.page === SEARCH) {
        // 1. find the website to keep
        const oneWebsiteIndex = getters.websites.findIndex((website) => website.active)
        // 2. disable all websites
        commit('resetWebsitesActiveState')
        // 3. enable the one we picked
        commit('setWebsiteActive', { i: oneWebsiteIndex, val: true })
      }
    },
    clickSearch ({ state, commit, dispatch }, page) {
      if (validInput(state.settings)) {
        // when making a new search with new settings we need to throw out old results on all tools
        commit('resetResults')
        dispatch('search', page)
      }
    },
    async search ({ state, commit }, page) {
      if (!isTool(page)) return

      const queries = buildQueries(state.settings, page)

      commit('setError', null)

      try {
        const results = await Promise.all(
          queries.map(async query => {
            const startTime = Date.now()
            console.info('API', query.name, '-->')

            const token = await auth0.getAccessTokenSilently()

            const response = await fetch(query.url, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })

            console.info('API', query.name, '<--', `${Math.floor((Date.now() - startTime) / 100) / 10}s`, query.url)

            if (response.status !== 200) { // 200 = OK
              const errJson = await response.json()
              throw errJson?.detail ?? errJson?.error ?? Error('Unknown API error')
            }

            const data = await response.json()

            return {
              ...query,
              data,
            }
          })
        )

        commit('setResults', {
          tool: page,
          results,
        })

        commit('setError', null)
        commit('setLastSearchSettings', cloneDeep(state.settings))
        updateUrl(state.lastSearchSettings)
      } catch (err) {
        commit('setError', err)
        console.error(err)
      }
    },
    // running this function on created() from App.vue
    // the second arg is passed from App.vue. it has more functions than the first arg we were passed here for some reason
    async onCreated ({ state, getters, dispatch }, store) {
      onCreatedLoadUrl({ getters, dispatch })

      onCreatedWatchRoute({ state, getters, dispatch, store })
    }
  }
}

async function onCreatedLoadUrl ({ getters, dispatch }) {
  await router.isReady()

  if (!isTool(getters.page)) return

  // if there are query params in the url when the page is loaded,
  // then grab those and perform a search with them
  const params = router.currentRoute.value.query

  if (!isEmpty(params)) {
    const paramWebsites = (params.websites || GAB).split(',')

    // turn it back to the actual format that we use
    const fixedWebsites = getters.websites.map((website) => ({
      ...website,
      active: paramWebsites.includes(website.name)
    }))

    dispatch('loadSettings', { ...params, websites: fixedWebsites })
  }

  dispatch('clearSearchWebsites')

  dispatch('search', getters.page)
}

async function onCreatedWatchRoute ({ state, getters, dispatch, store }) {
  await router.isReady()

  store.watch(() => router.currentRoute.value, (newRoute, oldRoute) => {
    // if we e.g. go from Home to Timeline. resurrect the query params
    if (!isTool(oldRoute.name) && isTool(newRoute.name)) {
      updateUrl(state.lastSearchSettings)
    }

    // if we searched on another tool, and we navigate to this tool but the same search hasn't been done here yet.
    if (isTool(getters.page) && isEmpty(getters.results)) {
      dispatch('clearSearchWebsites')

      dispatch('search', getters.page)
    }
  })
}