<template>
  <div class="label-toggle">
    <BaseToggle
      :model-value="modelValue"
      @update:model-value="$emit('update:model-value', $event)"
    />
    <div class="label" @click="$emit('update:model-value', !modelValue)">
      {{ label }}
    </div>
  </div>
</template>

<script>
import BaseToggle from './BaseToggle.vue'

export default {
  components: {
    BaseToggle,
  },
  props: {
    modelValue: Boolean,
    label: String,
  },
}
</script>

<style scoped>
.label-toggle {
  display: flex;
  gap: var(--spacing-md);
}

.label {
  cursor: pointer;
}
</style>