<template>
  <div class="input-search-term">
    <template v-if="!small">
      <p> Search Term (<a href="https://docs.openmeasures.io/docs/guides/public-app#search-term-options" target="_blank">docs</a>)</p>
      <q-btn-toggle
        no-caps
        spread
        toggle-color="our-blue"
        toggle-text-color="our-black"
        color="white"
        text-color="our-black"
        :model-value="esquery"
        @update:model-value="setEsQuery"
        :options="[
          {label: 'Basic', value: 'content'},
          {label: 'Boolean', value: 'boolean_content'},
          {label: 'Advanced', value: 'query_string'},
        ]"
      >
      </q-btn-toggle>
    </template>
    <div class="search-row">
      <InputSearchTermText
        :placeholder="placeholderText"
        :value="searchTerm"
        @input="setSearchTerm"
      />
      <!--TODO: remove search button?-->
      <FatButton padding="0.6rem">
        <BaseIcon name="search" size="1.7rem"/>
      </FatButton>
    </div>
  </div>
</template>
<script>
import InputSearchTermText from './InputSearchTermText'
import FatButton from './FatButton'
import BaseIcon from '@/components/BaseIcon'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    InputSearchTermText,
    FatButton,
    BaseIcon,
  },
  props: {
    small: Boolean,
  },
  computed: {
    ...mapGetters(['searchTerm']),
    ...mapGetters(['esquery']),
    placeholderText () {
      switch (this.esquery) {
        case 'content':
          return 'Example: qanon'
        case 'boolean_content':
          return 'Example: qanon OR wwg1wga'
        case 'query_string':
          return 'Example: (author: anon123 AND text: qanon)'
        default:
          return ''
      }
    }
  },
  methods: {
    ...mapMutations(['setSearchTerm']),
    ...mapMutations(['setEsQuery']),
  },
}
</script>

<style scoped lang="scss">
.input-search-term {
  color: var(--color-white);
}

a {
  color: var(--color-light-grey);
}

p {
  text-align: left;
  font-size: var(--fs-3);
  font-weight: 600;
  margin-bottom: var(--spacing-2);
  font: var(--primary-font);
}

.search-row {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-1);
  margin-top: var(--spacing-3);
}
</style>
