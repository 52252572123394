<template>
  <div class="text-cell">
    {{ truncated }}
  </div>
</template>

<script>
export default {
  props: {
    cell: Object,
  },
  computed: {
    truncated () {
      const maxLength = 250
      const tooLong = this.cell.value.length > maxLength

      if (tooLong) {
        return this.cell.value.slice(0, maxLength) + '…'
      } else {
        return this.cell.value
      }
    },
  },
}
</script>

<style scoped lang="scss">
.text-cell {
  max-width: 25rem;
  text-wrap: wrap;
}
</style>