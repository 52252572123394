<template>
  <div class="search-term-bar">
    <InputSearchTerm class="search-term"/>
  </div>
</template>

<script>
import InputSearchTerm from './input/InputSearchTerm.vue'

export default {
  components: {
    InputSearchTerm,
  },
}
</script>

<style scoped lang="scss">
.search-term-bar {
  background: url(~@/assets/pixely-bg.png) var(--color-primary-900);
  border-bottom: var(--border-width) solid var(--color-tan-500);
  padding: var(--spacing-huge) 0;
}

.search-term {
  margin: auto;
  width: 20rem;
}
</style>