<template>
  <div>
    <SearchControl
      class="search-control"
      v-model:displayType="displayType"
      :numResults="numResults"
      v-model:displayMedia="displayMedia"
      v-model:blurThumbnails="blurThumbnails"
      v-model:sortBy="sortBy"
      @doExport="doExport"
      @share="share"
      @summary="showSummary"
    />
    <div>
      <BodyError v-if="error" :error="error" />
      <template v-else>
        <SearchTable
          v-if="displayType === 'table'"
          :resultsData="resultsData"
          :loading="loading"
        />
        <div v-else-if="displayType === 'cards'">Card view</div>
        <div v-else-if="displayType === 'media'">Media view</div>
      </template>
    </div>
  </div>
</template>

<script>
import { normaliseResults } from '@/helpers/normalise-results'
import SearchControl from '../search/SearchControl.vue'
import BodyError from './BodyError'
import SearchTable from '../search/SearchTable.vue'

export default {
  components: {
    SearchControl,
    BodyError,
    SearchTable,
  },
  props: {
    lastSearchSettings: Object,
    results: Array,
    resultsAvailable: Boolean,
    loading: Boolean,
    error: Error
  },
  data () {
    return {
      displayType: 'table',
      displayMedia: true,
      blurThumbnails: true,
      sortBy: 'date',
    }
  },
  computed: {
    resultsData () {
      return normaliseResults(this.results)[0]
    },
    numResults () {
      return this.resultsData?.data.length
    },
  },
  methods: {
    doExport (type) {
      console.log('export as', type)
    },
    share () {
      console.log('clicked share button')
    },
    showSummary () {
      console.log('show "Search summary" modal')
    },
  },
}
</script>

<style scoped lang="scss">
.search-control {
  margin-bottom: var(--spacing-xl);
}
</style>
