<template>
  <button @click="$emit('click')" v-ripple class="button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  emits: ['click'],
}
</script>

<style scoped lang="scss">
.button {
  position: relative;
  background: var(--color-primary-600);
  color: var(--color-white);
  font-size: var(--fs-3);
  font-weight: 600;
  line-height: var(--fs-6);
  padding: var(--element-padding-md);
  text-transform: capitalize;
  cursor: pointer;
  border: none;
  box-shadow: var(--element-box-shadow) var(--color-primary-100);
}
</style>
