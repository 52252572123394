<template>
  <div class="chart">
    <BarChart ref="chart" :chartData="chartData" :options="chartOptions" :plugins="chartPlugins" />
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart.vue'
import { chartPlugins } from '@/helpers/graphs'

function color (name) {
  const style = getComputedStyle(document.body)
  return style.getPropertyValue(name)
}

export default {
  components: {
    BarChart
  },
  props: {
    resultsData: Array,
    searchSettings: Object,
    website: String,
    barType: String,
    subTitle: String
  },
  computed: {
    chartSubtitle () {
      function trunc (term) {
        if (!term) return ''
        return term.length > 15 ? term.slice(0, 15) + '…' : term
      }
      return this.subTitle + trunc(this.searchSettings.searchTerm) + ' [' + this.searchSettings.startDate + ' - ' + this.searchSettings.endDate + ']'
    },
    chartTitle () {
      return this.barType + this.website
    },
    chartData () {
      const names = this.resultsData
        .map((tag) => tag.key)
        .map((key) => key.replace(/^https?:\/\//, ''))
        .map((key) => key.replace(/^www\./, ''))
        .map((key) => {
          const max = 30

          if (key.length >= max) {
            return key.slice(0, max - 1) + '…'
          } else {
            return key
          }
        })
      const values = this.resultsData.map((tag) => tag.count)

      return {
        labels: names,
        datasets: [
          {
            backgroundColor: color('--color-purple'),
            data: values
          }
        ]
      }
    },
    chartPlugins () {
      return chartPlugins({ logoAtBottomRight: true })
    },
    chartOptions () {
      return {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        onResize: this.setHeight,
        layout: {
          padding: 10
        },
        plugins: {
          legend: {
            display: false
          },
          subtitle: {
            display: true,
            color: color('--color-light-text'),
            text: this.chartSubtitle,
            align: 'start',
            padding: {
              top: 5,
              bottom: 25,
            },
            font: {
              size: 16,
              weight: 500,
              family: 'Lexend, sans-serif'
            }
          },
          title: {
            display: true,
            color: color('--color-dark-text'),
            text: this.chartTitle,
            align: 'start',
            padding: {
              top: 0,
              bottom: 0,
            },
            font: {
              size: 24,
              weight: 'bold',
              family: 'Lexend, sans-serif'
            }
          },
          customCanvasBackgroundColor: {
            color: color('--color-white')
          }
        },
        scales: {
          x: {
            ticks: {
              font: {
                family: 'Lexend, sans-serif'
              }
            },
            beginAtZero: true,
            border: {
              dash: [4, 4],
            },
            grid: {
              color: color('--color-dividers')
            }
          },
          y: {
            ticks: {
              font: {
                family: 'Lexend, sans-serif'
              }
            },
            grid: {
              display: false
            }
          }
        },
        animation: {
          onComplete: (ctx) => {
            this.$refs.chart.base64Image = ctx.chart.toBase64Image()
          }
        }
      }
    }
  },
  methods: {
    downloadImage () {
      this.$refs.chart.downloadImage()
    },
    setHeight () {
      this.$refs.chart.$el.style.height = '400px'
    }
  }
}
</script>

<style scoped lang="scss"></style>
