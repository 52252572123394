<template>
  <q-table
    flat
    bordered
    row-key="id"
    :dense="$q.screen.lt.md"
    :visibleColumns="visibleColumns"
    :columns="columns"
    :rows="rowData"
    :loading="loading"
  >
    <template v-slot:body-cell="cell">
      <SearchCell :cell="cell"/>
    </template>
  </q-table>
</template>

<script>
import SearchCell from './SearchCell.vue'

export default {
  components: {
    SearchCell,
  },
  props: {
    resultsData: Object,
    loading: Boolean,
  },
  computed: {
    visibleColumns () {
      return ['date', 'type', 'text', 'site', 'channel', 'author', 'engagement', 'mediatype', 'thumbnail', 'menu']
    },
    rowData () {
      return this.resultsData?.data.map(result => ({
        text: result.text,
        author: result.author,
        engagement: {
          favorite_count: result.favorite_count,
          retweet_count: result.retweet_count,
        },
        menu: {
          link: result.link,
        },
      }))
    }
  },
}
</script>